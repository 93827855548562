/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Import jQuery and expose it globally
import $ from "jquery";
window.$ = $;
window.jQuery = $;

// Import Rails UJS
import "jquery-ujs";

// Import Turbolinks
import Turbolinks from "turbolinks";

Turbolinks.start();

console.log('application packs')

// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.
import 'promise-polyfill/src/polyfill';
import '../array_from_shim'

import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import Navigation from '../Navigation';
import ScrollyMenu from '../ScrollyMenu';
import ViewMore from '../ViewMore';
import YTModal from '../YTModal';
import ProductCardToggler from '../ProductCardToggler'
import Toggler from '../Toggler'
import SearchMain from '../SearchMain'
import SearchSecondary from '../SearchSecondary'
import Timeout from '../Timeout'
import Consent from '../Consent'
import AccessRequest from '../AccessRequest'
import GoogleTagManager from '../GoogleTagManager'

const application = Application.start()
const sharedContext = require.context('../controllers/shared', true, /_controller\.js$/)
application.load(definitionsFromContext(sharedContext))
// // uncomment if you put any stimulus controllers in the application folder
// const appContext = require.context('../controllers/application', true, /_controller\.js$/)
// application.load(definitionsFromContext(appContext))

window.APP = window.APP || {}
window.$ = window.jQuery = jQuery

$(() => {
  // Add CSRF tokens to every ajax request
  const token = $("meta[name='csrf-token']").attr('content')
  $(document).ajaxSend(function(event, xhr, options) {
    xhr.setRequestHeader('X-CSRF-Token', token)
  })

  // all pages need navigation
  new Navigation()

  // all pages need timeout popup
  new Timeout()

  // all pages need consent form
  // use settimeout to get around some horrifying race condition I can't figure out
  setTimeout(() => {
    new Consent()
  }, 100)

  // access request
  new AccessRequest()

  // GoogleTagManager
  new GoogleTagManager()

  // if certain DOM elements on page, run JS to handle them...
  const $scrollyMenu = $('#scrolly-menu')
  if ($scrollyMenu.length) {
    new ScrollyMenu($scrollyMenu)
  }

  const $viewMore = $('.js-view-more')
  if ($viewMore.length) {
    $viewMore.each(function() {
      new ViewMore($(this))
    })
  }

  const $ytmodals = $('.js-yt-modal')
  if ($ytmodals.length) {
    $ytmodals.each(function() {
      new YTModal($(this))
    })
  }

  const $productCardToggler = $('.js-toggle-attachments')
  if ($productCardToggler.length) {
    new ProductCardToggler($.makeArray($productCardToggler))
  }

  const $togglers = $('.js-toggle')
  if ($togglers.length) {
    new Toggler($.makeArray($togglers))
  }

  const $headerSecondary = $('.header-secondary')
  if ($headerSecondary.length) {
    new SearchSecondary($headerSecondary)
  }
  const $headerSearch = $('.header-search')
  if ($headerSearch.length) {
    new SearchMain($headerSearch)
  }
})
